// Open the Modal
function openModal() {
  document.getElementById('myModal').style.display = "block";
}

// Close the Modal
function closeModal() {
  document.getElementById('myModal').style.display = "none";
}

var slideIndex = 1;
showDivs(slideIndex);

function plusDivs(n) {
  var x = parseInt(slideIndex, 0) + parseInt(n, 0);
  slideIndex = x;
  showDivs(slideIndex);
}

// Thumbnail image controls
function currentSlide(el) {
  var n = el.getAttribute('data-index');
  showDivs(slideIndex = n);
}

function showDivs(n) {
  var i;
  var x = document.getElementsByClassName("mySlides");
  if (n > x.length) { slideIndex = 1 }
  if (n < 1) { slideIndex = x.length }
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }
  if (x[slideIndex - 1] !== undefined)
    x[slideIndex - 1].style.display = "block";
}


function growDiv_test_report(el) {
  var growDiv = el.parentNode.querySelector('.img');
  var growDiv_inner = el.parentNode.querySelector('.img .img_inner');
  if (growDiv.clientHeight) {
    growDiv.style.height = 0;
    el.classList.add('glyphicon-menu-down');
    el.classList.remove('glyphicon-menu-up');
  } else {
    growDiv.style.height = growDiv_inner.clientHeight + "px";
    el.classList.remove('glyphicon-menu-down');
    el.classList.add('glyphicon-menu-up');
  }
}

function growDiv_chat(el) {
  var growDiv = el.parentNode.querySelector('.grow_height');
  if (growDiv.clientHeight) {
    growDiv.style.height = 0;
  } else {
    growDiv.style.height = "auto";
  }
}

function openNav() {
  document.getElementById("menu_setting").style.width = "100%";
}
function closeNav() {
  document.getElementById("menu_setting").style.width = "0";
}

function openNavLesson() {
  document.getElementById("menu_lesson").style.width = "100%";
}
function closeNavLesson() {
  document.getElementById("menu_lesson").style.width = "0";
}

function set_current_class(el) {
  var current_class_id = el.getAttribute('data-id');
  var current_number_lesson = el.getAttribute('data-number_lesson');
  var current_lesson_id = el.getAttribute('data-lesson');
  if (current_lesson_id == 0) current_lesson_id = 1;
  localStorage.setItem('current_class_id', current_class_id);
  localStorage.setItem('current_number_lesson', current_number_lesson);
  localStorage.setItem('current_lesson_id', current_lesson_id);

  var i;
  var dots = el.parentNode.getElementsByClassName("item");
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  el.classList.add('active');
}